import {alpha, createTheme, styled} from "@material-ui/core/styles";
import {ThemePreferences} from "../../contexts/ThemePreferenceContext/ThemePreferenceProvider";
import {buttonWithClr, MuiTypography} from "../../styles/MaterialUIStyling";
import Button from "@material-ui/core/Button";

export const clrBlack = "hsl(220, 6%, 10%)";
export const clrWhite = "hsl(0, 0%, 95%)";

export const clrPrimary700 = "hsl(197, 69%, 5%)";
export const clrPrimary600 = "hsl(200, 68%, 15%)";
export const clrPrimary500 = "hsl(199, 68%, 30%)";
export const clrPrimary400 = "hsl(199, 68%, 45%)";
export const clrPrimary300 = "hsl(199, 74%, 59%)";
export const clrPrimary200 = "hsl(199, 74%, 73%)";
export const clrPrimary100 = "hsl(200, 88%, 87%)";

export const clrSecondary700 = "hsl(254, 45%, 6%)";
export const clrSecondary600 = "hsl(253, 45%, 16%)";
export const clrSecondary500 = "hsl(252, 44%, 33%)";
export const clrSecondary400 = "hsl(252, 44%, 49%)";
export const clrSecondary300 = "hsl(252, 58%, 63%)";
export const clrSecondary200 = "hsl(252,65%,77%)";
export const clrSecondary100 = "hsl(252, 100%, 94%)";

export const clrTertiary700 = "hsl(25, 91%, 17%)";
export const clrTertiary600 = "hsl(25, 91%, 33%)";
export const clrTertiary500 = "hsl(25, 91%, 50%)";
export const clrTertiary400 = "hsl(25, 89%, 59%)";
export const clrTertiary300 = "hsl(25, 100%, 74%)";
export const clrTertiary200 = "hsl(21, 100%, 85%)";
export const clrTertiary100 = "hsl(20, 100%, 92%)";

// TODO: Make the h range more between 200 and 210 and s range between 15 and 21 for cooler 😎 greys
export const clrGrey700 = "hsl(220, 21%, 17%)";
export const clrGrey600 = "hsl(225, 8%, 29%)";
export const clrGrey500 = "hsl(222, 11%, 45%)";
export const clrGrey400 = "hsl(211, 14%, 63%)";
export const clrGrey300 = "hsl(219, 14%, 73%)";
export const clrGrey200 = "hsl(224, 10%, 79%)";
export const clrGrey100 = "hsl(216, 16%, 88%)";

export const clrDanger700 = "hsl(347, 61%, 19%)";
export const clrDanger600 = "hsl(347, 68%, 32%)";
export const clrDanger500 = "hsl(346, 71%, 42%)";
export const clrDanger400 = "hsl(346, 81%, 55%)";
export const clrDanger300 = "hsl(347, 84%, 61%)";
export const clrDanger200 = "hsl(348, 77%, 81%)";
export const clrDanger100 = "hsl(345, 78%, 91%)";

export const clrWarning700 = "hsl(48, 71%, 23%)";
export const clrWarning600 = "hsl(48, 74%, 33%)";
export const clrWarning500 = "hsl(48, 58%, 50%)";
export const clrWarning400 = "hsl(48, 100%, 48%)";
export const clrWarning300 = "hsl(50, 91%, 73%)";
export const clrWarning200 = "hsl(52, 84%, 81%)";
export const clrWarning100 = "hsl(49, 100%, 89%)";

export const clrSuccess700 = "hsl(130, 58%, 17%)";
export const clrSuccess600 = "hsl(127, 57%, 25%)";
export const clrSuccess500 = "hsl(126, 55%, 37%)";
export const clrSuccess400 = "hsl(126, 46%, 49%)";
export const clrSuccess300 = "hsl(126, 57%, 65%)";
export const clrSuccess200 = "hsl(122, 67%, 80%)";
export const clrSuccess100 = "hsl(118, 100%, 93%)";

const body1 = 15.4;
const horizontalPaddingConstant = "0.5rem";

const clrBorder = alpha(clrPrimary100, 0.3);
export const borderRadiusMedium = "10px";
export const borderRadiusHeavy = "20px";

const hideNumberArrows = {
  '& input[type=number]': {
    '-moz-appearance': 'textfield'
  },
  '& input[type=number]::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0
  },
  '& input[type=number]::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0
  }
}

const listItemBorderRadius = {
  "&:first-child": {
    borderTopLeftRadius: borderRadiusMedium,
    borderTopRightRadius: borderRadiusMedium,
  },
  "&:last-child": {
    borderBottomLeftRadius: borderRadiusMedium,
    borderBottomRightRadius: borderRadiusMedium,
  },
  "&:only-child": {
    borderRadius: borderRadiusMedium,
  }
};

const outlinedButtonBorder = "2px solid";

export const getHumanMaterialUIStyling = (themePreference: ThemePreferences, isDarkTheme: boolean) => {

  return createTheme({
    palette: {
      type: themePreference,
      background: {
        default: isDarkTheme ? "linear-gradient(0deg, #151521 66.85%, #4265AF 100%)" : "#fafafa",
        paper: isDarkTheme ? clrPrimary600 : "#fff"
      },
      common: {
        black: clrBlack
      },
      primary: {
        main: clrPrimary400,
        contrastText: clrWhite,
        dark: clrPrimary500,
        // light: darken(clrPrimaryBlue, 0.66)
      },
      secondary: {
        main: clrSecondary400,
        contrastText: clrSecondary100,
        dark: clrSecondary500,
      },
      grey: {
        100: clrGrey100,
        200: clrGrey200,
        300: clrGrey300,
        400: clrGrey400,
        500: clrGrey500,
        600: clrGrey600,
        700: clrGrey700,
      },
      error: {
        main: clrDanger400,
      },
      warning: {
        main: clrWarning400,
        contrastText: clrWarning100,
        dark: clrWarning500,
      },
      info: {
        main: clrPrimary400,
        contrastText: clrPrimary100,
        dark: clrPrimary500,
      },
      success: {
        main: clrSuccess400,
        contrastText: clrSuccess100,
        dark: clrSuccess500,
      },
      text: {
        primary: isDarkTheme ? clrGrey200 : clrBlack,
        secondary: isDarkTheme ? clrGrey200 : clrGrey500,
      },
    },
    shape: {
      borderRadius: 10
    },
    overrides: {
      MuiAppBar: {
        colorPrimary: {
          backgroundColor: isDarkTheme ? "transparent" : clrWhite
        }
      },
      MuiPaper: {
        elevation1: {
          boxShadow: `0 10px 20px ${alpha(clrPrimary700, 0.2)}, 0 2px 4px ${alpha(clrPrimary700, 0.5)};`,
        },
        rounded: {
          border: "1px solid " + (isDarkTheme ? clrBorder : "lightgrey"),
          backgroundColor: isDarkTheme ? clrPrimary600 : "#fff",
        }
      },
      MuiButton: {
        root: {
          fontSize: 13.4,
          padding: "11.5px 16px",
        },
        textSizeLarge: {
          fontSize: "13.4px",
          letterSpacing: "1.25px"
        },
        sizeSmall: {
          fontSize: "13.4px",
          letterSpacing: "1.25px",
          padding: "5px 12px", // vibes based padding
        },
        outlinedPrimary: {
          border: outlinedButtonBorder,
          '&:hover': {
            border: outlinedButtonBorder
          },
          "& .MuiButton-label": {
            color: isDarkTheme ? clrPrimary100 : clrPrimary400,
          }
        },
        outlinedSecondary: {
          border: outlinedButtonBorder,
          '&:hover': {
            border: outlinedButtonBorder
          },
          "& .MuiButton-label": {
            color: isDarkTheme ? clrSecondary100 : clrSecondary400,
          }
        },
        outlined: {
          padding: "9px 13px",
          border: outlinedButtonBorder,
          '&:hover': {
            border: outlinedButtonBorder
          },
          color: clrGrey100,
        },
        contained: {
          backgroundColor: clrGrey100,
        },
        text: {
          padding: "12px 16px",
        },
      },
      MuiButtonBase: {
        root: {
          fontSize: 13.4,
          letterSpacing: 0.5,
          fontWeight: 500,
          padding: "12px 16px",
        }
      },
      MuiFormControl: {
        marginDense: {
          marginTop: 4,
          marginBottom: 4,
        }
      },
      MuiOutlinedInput: {
        root: {
          ...hideNumberArrows,
        },
        marginDense: {
          paddingTop: 4,
          paddingBottom: 4,
          paddingLeft: 6,
          paddingRight: 6,
        },
        input: {
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 6,
          paddingRight: 6,
        },
        notchedOutline: {
          // I'm glad you're seeing this ✨magic number✨, so based on the comment linked below, material ui figures out the notched outline width based on the font size.
          // https://github.com/mui/material-ui/issues/16833#issuecomment-1136374597
          // The exact number was based on trial and error. Out of all the possible things I can be doing right now and all the possible timelines I can be on, I'm here...
          fontSize: 14.70,
        }
      },
      MuiInputBase: {
        root: {
          fontSize: 13.4
        }
      },
      MuiFormHelperText: {
        contained: {
          marginLeft: 7,
          marginRight: 7,
        }
      },
      MuiInput: {
        root: {
          ...hideNumberArrows,
        }
      },
      MuiInputLabel: {
        outlined: {
          background: isDarkTheme ? "inherit" : "white",
        }
      },
      MuiBottomNavigationAction: {
        label: {
          fontSize: 11,
          minHeight: 32,
          maxWidth: 80,
          "&$selected": {
            fontSize: 11.5,
          }
        }
      },
      MuiTooltip: {
        tooltip: {
          backgroundColor: "hsl(198, 71%, 11%)",
          border: "1px solid " + clrBorder,
          fontSize: 12,
          letterSpacing: 0.4,
          fontWeight: 400,
          color: isDarkTheme ? clrGrey100 : clrWhite,
        },
        arrow: {
          color: "hsl(198, 71%, 11%)",
          "&:before": {
            border: "1px solid " + clrBorder,
          }
        }
      },
      MuiTable: {
        root: {
          border: "2px solid " + alpha(clrPrimary200, 0.3),
          borderRadius: borderRadiusMedium,
          borderCollapse: "separate"
        }
      },
      MuiTableHead: {
        root: {
          backgroundColor: isDarkTheme ? clrPrimary600 : clrWhite,
          borderBottom: "2px solid " + alpha(clrPrimary200, 0.3),
        }
      },
      MuiTableRow: {
        root: {
          "&:last-child > *": {
            borderBottom: "unset",
          }
        }
      },
      MuiTableCell: {
        root: {
          borderBottom: "1px solid " + alpha(clrPrimary200, 0.3),
          fontSize: body1,
        }
      },
      MuiChip: {
        outlined: {
          backgroundColor: isDarkTheme ? alpha(clrPrimary400, 0.15) : "initial",
          color: isDarkTheme ? clrGrey100 : clrBlack,
          borderRadius: borderRadiusMedium,
          height: "unset",
          padding: "0.5rem 0.25rem",
          "$clickable&:hover, $clickable&:focus, $deletable&:focus": {
            backgroundColor: alpha(clrPrimary400, 0.15),
          },
          // look below if you change this
          "&.MuiChip-sizeSmall": {
            padding: "0.25rem 0.15rem",
          },
          printColorAdjust: "exact",
          "@media print": {
            backgroundColor: alpha(clrPrimary400, 0.25),
            border: "1px solid " + alpha(clrPrimary400, 0.5),
            color: clrPrimary700,
            fontSize: 12,
          },
          width: "fit-content",
        },
        sizeSmall: {
          // look above if you chane this
          padding: "0.25rem 0.15rem",
        },
        deleteIcon: {
          color: isDarkTheme ? clrPrimary500 : "lightgray",
          '&:hover': {
            color: isDarkTheme ? clrPrimary400 : "darkgray",
          }
        }
      },
      MuiTab: {
        root: {
          marginLeft: "auto",
          marginRight: "auto",
        }
      },
      MuiSvgIcon: {
        colorAction: {
          color: isDarkTheme ? clrGrey400 : "grey",
        }
      },
      MuiListItem: {
        root: {
          "&$selected": listItemBorderRadius,
          "&:hover": listItemBorderRadius,
        },
      }
    },
    typography: MuiTypography,
  });
}

export const HumanOrangeButton = buttonWithClr(clrTertiary400, clrWhite);

export const HumanOutlinedTertiaryButton = styled(Button)(({theme}) => ({
  backgroundColor: "transparent",
  borderColor: clrTertiary400,
  "& .MuiButton-label": {
    color: clrTertiary100,
  },
  "&:hover": {
    backgroundColor: alpha(clrTertiary400, 0.08),
    borderColor: clrTertiary400,
  }
}));

export const HumanOutlinedDangerButton = styled(Button)(({theme}) => ({
  backgroundColor: "transparent",
  borderColor: clrDanger400,
  "& .MuiButton-label": {
    color: clrDanger100,
  },
  "&:hover": {
    backgroundColor: alpha(clrDanger400, 0.08),
    borderColor: clrDanger600,
  }
}));

export const HumanTextDangerButton = styled(Button)(({theme}) => ({
  color: clrDanger400,
  "&:hover": {
    backgroundColor: alpha(clrDanger400, 0.08),
  }
}));

